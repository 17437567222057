import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from '../redux/actions';
import { Link } from 'react-router-dom';
import settingsIcon from '../images/settings01.png';

const Settings = () => {
    const dispatch = useDispatch();
    const logoURL = useSelector((state) => state.logoURL);

    const handleSignOut = () => {
        dispatch(signOut());
    };

    return (
        <div className="min-h-screen flex justify-center">
            <div className="w-full max-w-screen-lg flex flex-col items-center space-y-3 px-4 md:px-16">
                <div className="w-full flex items-center justify-between p-2 bg-gray-100" style={{ height: '75px' }}>
                    <img src={logoURL} alt="Logo" className="h-15" style={{ width: "auto", height: '60px' }} />
                    <Link to="/settings" className="h-6 w-6">
                        <img src={settingsIcon} alt="Settings" className="h-full w-full" />
                    </Link>
                </div>

                {/* Buttons */}
                <div className="flex flex-col items-center space-y-4 mt-8">
                    <Link
                        to="/"
                        className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600 text-xl w-full text-center"
                    >
                        Payments Page
                    </Link>
                    <button
                        onClick={handleSignOut}
                        className="px-4 py-2 text-white bg-red-500 rounded hover:bg-red-600 text-xl w-full"
                    >
                        Sign Out
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Settings;
