// reducers.js

const initialState = {
    value: 0,
    signedInStatus: false,
    userDetails: {},
    trevorURL: "",
    trevorURLAvailable: false,
    userDetailsAvailable: false,
    userDetailsURL: "",
    userTenant: "",
    userEmail: "",
    donorEmail: "",
    userDetailsProvided: false,
    fundraisingTargets: [],
    userSelectedFundraisingTarget: "",
    posResponse: {},
    awaitingPOSResponse: false,
    awaitingPOSStatusResponse: false,
    posStatus: {},
    logoURL: ""

};

const counterReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'counter/increment':
            return { ...state, value: state.value + 1 };
        case 'counter/decrement':
            return { ...state, value: state.value - 1 };
        case 'trevor/setURL':
            return { ...state, trevorURL: action.payload, trevorURLAvailable: true };
        case 'trevor/setUserURL':
            return { ...state, userDetailsURL: action.payload, userDetailsAvailable: true };
        case 'trevor/setUserDetails':
            return { ...state, userEmail: action.payload.email, userTenant: action.payload.tenant, userDetailsProvided: true };
        case 'trevor/clearUserDetails':
            return { ...state, userDetailsURL: "", userEmail: "", userTenant: "", userDetailsProvided: false, userDetailsAvailable: false };

        case 'user/signIn':
            return { ...state, signedInStatus: true, userDetails: action.payload };
        case 'user/signOut':
            return { ...state, signedInStatus: false, userDetails: {} };

        case 'user/setTenantID':
            return { ...state, userTenant: action.payload.tenantID, fundraisingTargets: action.payload.fundraisingTargets };
        case 'user/fundraisingTargets':
            return { ...state, fundraisingTargets: action.payload };
        case 'user/setDonorEmail':
            return { ...state, donorEmail: action.payload };
        case 'user/setPOSResponse':
            return { ...state, posResponse: action.payload, awaitingPOSResponse: false };
        case 'user/userSelectedFundraisingTarget':
            return { ...state, userSelectedFundraisingTarget: action.payload };
        case 'user/setAwaitingPOSResponse':
            return { ...state, awaitingPOSResponse: action.payload };
        case 'user/setAwaitingPOSStatusResponse':
            return { ...state, awaitingPOSStatusResponse: action.payload };
        case 'user/setLogoURL':
            return { ...state, logoURL: action.payload };
        case 'user/setPOSstatus':
            return { ...state, posStatus: action.payload, awaitingPOSStatusResponse: false };
        default:
            return state;
    }
};

// If you have other reducers, you can combine them using combineReducers
// import { combineReducers } from 'redux';
// export default combineReducers({ counter: counterReducer, ... });

export default counterReducer;