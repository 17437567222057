// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { onAuthStateChanged } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// Required for side-effects
import "firebase/firestore";


import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAWvBNcOg3DLjYoQnTg5dgDgrayW9SMSQU",
    authDomain: "stripepos-infaque.firebaseapp.com",
    projectId: "stripepos-infaque",
    storageBucket: "stripepos-infaque.appspot.com",
    messagingSenderId: "318679547707",
    appId: "1:318679547707:web:d7bfa737ff3cc5f7eec11e",
    measurementId: "G-2LKEB5JC4F"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth();
const provider = new GoogleAuthProvider();
provider.setCustomParameters({
    prompt: 'select_account'
});
const db = getFirestore(app)

export { auth, provider, signInWithPopup, onAuthStateChanged, db };